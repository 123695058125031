import { Component, OnInit } from '@angular/core';
import { RateService } from '../services/rate.service';
import { Rate } from '../intefaces/rate.interface';
import { Router } from '@angular/router';

declare interface DataTable {
  headerRow: string[];
  
  dataRows: any[];
}
declare const $: any;
@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.css']
})
export class RateComponent implements OnInit {
  public dataTable: DataTable;

  rates:Rate[] = []
  rate: Rate ={
    rate: '0,1'
  }
  prub = 'prueba';
  constructor(private rateService:RateService,private router:Router) { 
    this.rates.push(this.rate);
    // //console.log(this.rates)
    this.rateService.getRates().subscribe(data =>{
      // //console.log(data);
      
      this.rates = data;
      this.rateService.saveRate(this.rates);
      // //console.log(this.rates)
      this.dataTable = {
        headerRow :['TASA','Editar'],
        dataRows: this.rates,
      };
    })
  

  }
  editar(id:string){


    this.rateService.saveId(id);
    this.router.navigate(['/dashboard/Rate/edit']);


  }
  ngOnInit() {
  }
  ngAfterViewInit() {
    
   
    $('#datatables').DataTable({
      "pagingType": "full_numbers",
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Buscar asignación",
      },
      

    });
    const table = $('#datatables').DataTable();

     // Edit record
      table.on('click', '.edit', function(e) {
        const $tr = $(this).closest('tr');
        const data = table.row($tr).data();
        alert('You press on Row: ' + data[0] + ' ' + data[1] + ' ' + data[2] + '\'s row.');
        e.preventDefault();
      });

      // Delete a record
      table.on('click', '.remove', function(e) {
        const $tr = $(this).closest('tr');
        table.row($tr).remove().draw();
        e.preventDefault();
      });

      //Like record
      table.on('click', '.like', function(e) {
        alert('You clicked on Like button');
        e.preventDefault();
      });

      $('.card .material-datatables label').addClass('form-group');
      

    }
}
