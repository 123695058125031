import { Component, OnInit } from '@angular/core';
import { Entitys } from '../intefaces/entitys';
import { Entity } from '../intefaces/entity';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  first:Entity = {
    entity: 'Entidad 1',
    quotas: 1000,
    cost: 100000,
    place: 1
  };
  second: Entity = {
    entity: 'Entidad 2',
    quotas: 1000,
    cost: 100000,
    place: 3
  };
  third:Entity = {
    entity: 'Entidad 3',
    quotas: 1000,
    cost: 100000,
    place: 2
  };
  fourth:Entity = {
    entity: 'Entidad 4',
    quotas: 1000,
    cost: 100000,
    place: 4
  };
  fifth:Entity = {
    entity: 'Entidad 5',
    quotas: 1000,
    cost: 100000,
    place: 5
  };


  newEntity: Entitys = {
      first: this.first,
      second: this.second,
      third: this.third,
      fourth: this.fourth,
      fifth: this.fifth

  };
  enitys: String[] = ['Entidad 1', 'Entidad 2','Entidad 3', 'Entidad 4', 'Entidad 5'];

  constructor() { }

  ngOnInit() {
  }
  save(){
    // //console.log('save');
  }

}
