import { Component, OnInit } from '@angular/core';
import { Form } from '../intefaces/form';
import swal from 'sweetalert2';
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
import { CustomersService } from '../services/customers.service';
import { Request } from '../intefaces/request.interface';
import { customerRequest } from '../intefaces/customerRequest.interface';
import { Loan } from '../intefaces/loan.interface';
import { Patient } from '../intefaces/patient.interface';
import { Router } from '@angular/router';
import { clinics } from '../intefaces/clinics';
import { ClinicsService } from '../services/clinics.service';
import { Clinic } from '../intefaces/clinic.interface';
import { Rate } from '../intefaces/rate.interface';
import { RateService } from '../services/rate.service';
import { Upload } from 'aws-sdk/clients/devicefarm';
import { UploadService } from '../upload.service';
import { Customer } from '../intefaces/customer.interface';
import { WebsocketService } from '../services/webSocket.service';

declare const $: any;
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  state = 1;
  document:File;
  document2:File;
  document3:File;
  progreso:boolean = null;
  customerRequest:customerRequest ={
    activity: "",
    address: "",
    anotherIncome: "",
    birthday: "",
    city: "",
    customerId: null,
    district: "",
    documentDate: "",
    documentId: 0,
    documentType: "",
    email: "",
    gender: "",
    income: 0,
    names: "",
    phone: 0,
    surnames: "",
    timestamp: 0,
    pension:"",
    eps: "",
    birthplace:""
  }
  customer:Customer = {
   names: '',
   surnames: '',
   gender: '',
   documentId:null,
   documentType: '',
   documentDate: null,
   birthday: null,
    phone: null,
    email: '',
    activity: '',
    income: null,
    anotherIncome: null,
    address: null,
    city: '',
    district: '',
    pension: '',
    eps : '',
    birthplace: ''

 }
 usu = localStorage.getItem('usu');
 loan:Loan = {
  requestAmount: 0,
  rate: 0,
  discount: null,
  total: 0,
  time: 0,
  clinic: '',
  clinicZone:'',
  observations: '',
  files:[],
  userId: 'NA',
  createdBy: this.usu
 }
 patient:Patient ={
  names: '',
  surnames: '',
  documentType: '',
  documentId:0,
  orthodontics: false
 }
 request:Request = {
   Customer:null,
   Loan:null,
   Patients: [],
 };
 patientP= false;
 cc:number = null;
 flag = false;
 flagDoc = false;
 bandera = false;
 encontrar = false;
 genders:string [] = ['Masculino','Femenino'];
 documents:string[] =  ['Cédula ciudadanía','Cédula extranjería'];
 activitys:string[] = ['Empleado','Independiente','Rentista','Pensionado']; 
 plazo:number[]= [];
 patients:Patient[] = [];
 rates:Rate [] = [];

 contadorTexto:string ='s';
 contadorTexto2:string ='s';
 contadorTexto3:string ='s';
 contadorTexto4:string ='s';
 contadorTexto5:string ='s';
 contadorTexto6:string ='s';
 auto = false;
 upCc;
 upHa;
 upOt;
 clinic:Clinic[] = [];
 clinicAll = {
    clinicId: "",
    name: "",
    zone: ""

 }
 banderaAd1 = false;
 banderaAd2 = false;
 banderaAd3 = false;
  constructor(private customerServices:CustomersService, private router:Router, private clinicServices:ClinicsService, private WebsocketService: WebsocketService,private rateServives:RateService, private uploadServices:UploadService) {
    // let centinela:Patient ={
    //   names: '',
    //   surnames: '',
    //   documentType: '',
    //   documentId:0,
    //   orthodontics: false
    // };
    // this.patients.push(centinela);
    this.WebsocketService.messages.subscribe(data =>{
   
      // if(data.toString() == 'Echo: new info'){
      //   $.notify({
      //     icon: 'notifications',
      //    message: "Una nueva solicitud fue creada",
      //   }, {
      //    type: 'warning',
      //    timer: 1000,
      //    placement: {
      //        from: 'top',
      //        align: 'right'
      //    },
      //    template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
      //    '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
      //    '<i class="material-icons" data-notify="icon">notifications</i> ' +
      //    '<span data-notify="title">{1}</span> ' +
      //    '<span data-notify="message">{2}</span>' +
      //    '<div class="progress" data-notify="progressbar">' +
      //      '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
      //    '</div>' +
      //    '<a href="{3}" target="{4}" data-notify="url"></a>' +
      //  '</div>'
      //   })
        
      // }
    })
    this.rateServives.getRates().subscribe(data =>{
      this.rates = data;
    })
    this.clinicServices.getClinics().subscribe(data =>{
      this.clinic = data;
    })


   }

  ngOnInit() {
    
    for(let i =0; i<37;i+=6){
      
      if(i!=0){
        this.plazo.push(i);
      }
      
    }
    

  }
  complete(){
    // //console.log(this.auto)
    if (!this.auto){
      this.patient.names = this.customerRequest.names
      this.patient.surnames = this.customerRequest.surnames
      this.patient.documentType = this.customerRequest.documentType
      this.patient.documentId = this.customerRequest.documentId
      
    }else{
      this.patient.names = ' '
      this.patient.surnames = ''
      this.patient.documentType = ''
      this.patient.documentId =0
    }
  
  
  }
  add(){
    let centinela:Patient ={
      names: '',
      surnames: '',
      documentType: '',
      documentId:0,
      orthodontics: false
    };
    
    this.contadorTexto +='s';
    this.contadorTexto2 +='s';
    this.contadorTexto3+='s';
    this.contadorTexto4 +='s';
    this.contadorTexto5 +='s';
    this.contadorTexto6 +='s';
    this.patients.push(centinela);
   
    
    this.patientP = true;
  }
  remove(){
    this.patientP = false;
    this.patients.pop();

  }
  changeState() {
    if ( this.state === 1 ) {
      this.state = 2;
      return true;
    }
    if (this.state === 2) {
      this.state = 3;
      return true;
    } else {
      this.state = 1;
      return  true;
    }

  }
  prevState() {
    this.state -= 1;
  }
  download(){
    window.open("../../assets/doc/doc.pdf");
  }

  search(){
    this.encontrar = false;
    this.customerServices.getCustomer(this.cc).subscribe(data=>{
      if(data == 'No se encontrarón resultados'){
        // //console.log('no hay');
        $.notify({
          icon: 'notifications',
         message: "No se encontró ningún cliente con este número de identificación.",
        }, {
         type: 'warning',
         timer: 1000,
         placement: {
             from: 'top',
             align: 'right'
         },
         template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
         '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
         '<i class="material-icons" data-notify="icon">notifications</i> ' +
         '<span data-notify="title">{1}</span> ' +
         '<span data-notify="message">{2}</span>' +
         '<div class="progress" data-notify="progressbar">' +
           '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
         '</div>' +
         '<a href="{3}" target="{4}" data-notify="url"></a>' +
       '</div>'
        })
        this.customerRequest = {
          activity: "",
          address: "",
          anotherIncome: "",
          birthday: "",
          city: "",
          customerId:null,
          district: "",
          documentDate: "",
          documentId: 0,
          documentType: "",
          email: "",
          gender: "",
          income: 0,
          names: "",
          phone: 0,
          surnames: "",
          timestamp: 0
       
        }
        // //console.log(this.customerRequest.timestamp);
        this.flag = false;
        this.flagDoc = false;
        this.customerRequest.documentId = this.cc;
        this.bandera = true;
      }else {
        if(data.length > 0){

          $.notify({
            icon: 'notifications',
           message: "Se encontró un cliente con este número de identificación.",
          }, {
           type: 'success',
           timer: 1000,
           placement: {
               from: 'top',
               align: 'right'
           },
           template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
           '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
           '<i class="material-icons" data-notify="icon">notifications</i> ' +
           '<span data-notify="title">{1}</span> ' +
           '<span data-notify="message">{2}</span>' +
           '<div class="progress" data-notify="progressbar">' +
             '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
           '</div>' +
           '<a href="{3}" target="{4}" data-notify="url"></a>' +
         '</div>'
          })
          // //console.log(data);
          this.customerRequest = data[0];
          // //console.log(this.customerRequest);
          this.customer.activity = this.customerRequest.activity;
          this.customer.address = this.customerRequest.address;
          this.customer.anotherIncome = Number( this.customerRequest.anotherIncome);
          this.customer.birthday = this.customerRequest.birthday;
          this.customer.city = this.customerRequest.city;
          this.customer.district = this.customerRequest.district;
          this.customer.documentId = this.customerRequest.documentId;
          this.customer.documentDate = this.customerRequest.documentDate;
          this.customer.documentType = this.customerRequest.documentType;
          this.customer.email = this.customerRequest.email;
          this.customer.gender = this.customerRequest.gender;
          this.customer.income = this.customerRequest.income;
          this.customer.names = this.customerRequest.names;
          this.customer.phone = this.customerRequest.phone;
          this.customer.surnames = this.customerRequest.surnames;
          // //console.log(this.customer);
          this.encontrar = true;
          this.flag = true;
          this.flagDoc = true;
          this.bandera = true;
        }
      }
      
      
      




    })
  }
  async save() {
    $('#save').attr("disabled", true);
    if(false){
      swal({
        title: "La tasa no puede ser 0 ",
        text: "Valide el formulario",
        buttonsStyling: false,
        confirmButtonClass: "btn btn-danger",
      }
        ).catch(swal.noop)
    }
    else {

      this.request.Customer = this.customerRequest;
      this.request.Loan = this.loan;
      this.request.Patients.push(this.patient);
  
      for(let i = 0; i < this.patients.length;i++){
        this.request.Patients.push(this.patients[i]);
      }
  
      
  
      // //console.log(this.clinicAll);
      this.request.Loan.clinic = this.clinicAll.name;
      this.request.Loan.clinicZone = this.clinicAll.zone;
      // //console.log(this.request);
  
      if(this.banderaAd1){
        let url;
        url = await this.uploadServices.fileUpload(this.upCc);
        // //console.log(url);
       
        // //console.log(this.request.Loan.files)
        
      }
      if(this.banderaAd2){
        let url;
        url = await this.uploadServices.fileUpload(this.upHa);
        // //console.log(url);
        
        
      }
      if(this.banderaAd3){
        let url;
        url = await this.uploadServices.fileUpload(this.upOt);
        // //console.log(url);
        
        // //console.log(this.request.Loan.files)
        
      }
      this.request.Loan.files = this.uploadServices.getFiles();
      this.uploadServices.setFiles();
  
  
      // //console.log(this.request.Loan.files)
      if(this.request.Loan.createdBy === null){
        swal({
          title: "Su sesión ha expirado  ",
          text: "Ingrese nuevamente",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-danger",
        }
          ).catch(swal.noop)
  
      }else{
        this.customerServices.newCustomer(this.request).subscribe(data =>{
          // //console.log(data);
    
          //console.log('');
          swal({
            title: "Solicitud guardada",
            text: "",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-success",
            type: "success",
            onAfterClose: () => this.router.navigate(['/dashboard/Search'])
        }).catch(swal.noop)
        })
        
      }
    }


  }

  guardarSocket(){
    let message = {
      action: '',
      message: ''
    };
    message.action = 'inbox';
    message.message = 'Hello';
    this.WebsocketService.messages.next(message);
  }
  onChange(event) {
    let file:File = event.target.files[0];
    this.upCc = file;
    //console.log(file);
    // //console.log("entro")
    //console.log(event.target.files);
    this.banderaAd1 = true;
    // this.upCc = event.target.files;
  }
  onChange2(event) {
    let file:File = event.target.files[0];
    this.upHa = file;
    //console.log(file);
    // //console.log("entro")
    //console.log(event.target.files);
    this.banderaAd2 = true;
    // this.upCc = event.target.files;
  }
  onChange3(event) {
    let file:File = event.target.files[0];
    this.upOt = file;
    //console.log(file);
    // //console.log("entro")
    //console.log(event.target.files);
    this.banderaAd3 = true;
    // this.upCc = event.target.files;
  }
}
