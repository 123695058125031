import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentId'
})
export class DocumentIdPipe implements PipeTransform {

  transform(value: string): any {
    let res = value.split(":",1)
   
    return res;
  }

}
