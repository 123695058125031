import { Component, OnInit } from '@angular/core';
import { Rate } from 'src/app/intefaces/rate.interface';
import { RateService } from 'src/app/services/rate.service';
import swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.css']
})
export class RatesComponent implements OnInit {
  rate:Rate = {
    rate: ''
  }
  id:any ='';
  idRoute= ''
  constructor(private rateServices:RateService,private router:Router ,private route:ActivatedRoute) {
    this.idRoute = this.route.snapshot.paramMap.get('id');
    // //console.log(this.idRoute);
    if(this.idRoute == 'edit'){
      this.id = this.rateServices.getIdRate();
      // //console.log(this.id);
      this.rateServices.getRate(this.id).subscribe(data =>{
        // //console.log(data);
        this.rate = data[0];
        
      })
    }


   }

  ngOnInit() {
  }
  save(){
    if(this.idRoute == 'edit'){
      this.rateServices.putRate(this.rate).subscribe(data =>{
        swal({
          title: "Editado",
          text: "",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-success",
          type: "success",
          onAfterClose: () => this.router.navigate(['/dashboard/Rate'])
        }).catch(swal.noop)
      },error =>{
        swal({   
          title: "Ups!",   
          text: "El usuario ya  existe.",
          type:"error",   
          timer: 2000,   
          showConfirmButton: false 
          });

          })
    }
    else{
      this.rateServices.newRate(this.rate).subscribe(data =>{

        swal({
          title: "Guardado",
          text: "",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-success",
          type: "success",
          onAfterClose: () => this.router.navigate(['/dashboard/Rate'])
        }).catch(swal.noop)


      },error=>{
        swal({   
            title: "Ups!",   
            text: "",
            type:"error",   
            timer: 2000,   
            showConfirmButton: false 
      });
      })
    }
  }

}
  
