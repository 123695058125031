import { Injectable } from '@angular/core';
import { url} from '../intefaces/url';
import { Http,Headers } from '@angular/http';
import { Login } from '../intefaces/login.interface';
import { map, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  uri = '';
  constructor(private http:Http) { 
    this.uri = url;
  }

  validateLogin(login:Login){
    let request = login;
    this.uri = '';
    this.uri = url;
    let body = request;
    this.uri += 'user/sign-in'
    // console.log(body);
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.post(this.uri, body, {headers: headers}).pipe(
      map(res => res.json())
    );
  }
}
