import { Component, OnInit } from '@angular/core';
import { Clinic } from '../../intefaces/clinic.interface';
import { ClinicsService } from 'src/app/services/clinics.service';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'app-clinic',
  templateUrl: './clinic.component.html',
  styleUrls: ['./clinic.component.css']
})
export class ClinicComponent implements OnInit {
  clinic:Clinic = {
    name:'',
    zone:'',
    status:true
  }
  zonas = [
    'Zona 1',
    'Zona 2',
    'Zona 3',
    'Zona 4',
    'Zona 5',
    'Zona Centro',


  ];
  id = '';
  idRoute= '';
  constructor(private clinicServices:ClinicsService, private router:Router,private route:ActivatedRoute) { 
    this.idRoute = this.route.snapshot.paramMap.get('id');
    if(this.idRoute == 'edit'){
        this.id = this.clinicServices.getId();
        this.clinicServices.getClinic(this.id).subscribe(data =>{
          
          // //console.log(data);
          this.clinic = data[0];
        })

    }


  }

  ngOnInit() {
  }
  save(){
    
    if(this.idRoute == 'edit'){
      this.clinicServices.putClinic(this.clinic).subscribe(data =>{
        // //console.log(data);
        swal({
          title: "Editado",
          text: "",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-success",
          type: "success",
          onAfterClose: () => this.router.navigate(['/dashboard/Clincs'])
        }).catch(swal.noop)
          
        }, error => {
          swal({   title: "Ups!",   
          text: "La clinica ya  existe.",
          type:"error",   
          timer: 2000,   
          showConfirmButton: false 
      });
      })



    }else{

      this.clinicServices.newClinic(this.clinic).subscribe(data =>{
        // //console.log(data);
        swal({
          title: "Guardado",
          text: "",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-success",
          type: "success",
          onAfterClose: () => this.router.navigate(['/dashboard/Clincs'])
        }).catch(swal.noop)
          
        }, error => {
          swal({   title: "Ups!",   
          text: "La clinica ya  existe.",
          type:"error",   
          timer: 2000,   
          showConfirmButton: false 
      });
      })

    }
   

  }
}
