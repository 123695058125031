import { Injectable } from '@angular/core';
import { url} from '../intefaces/url';
import { Http,Headers } from '@angular/http';
import { HttpErrorResponse  } from '@angular/common/http';
import { User } from '../intefaces/user.interface';
import { map } from 'rxjs/operators';
import { usu } from '../intefaces/usuario.interface';
import { HttpClient } from '@angular/common/http';

declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class UsersService {
  uri = url + 'user';
  userId= '';
  constructor(private http:HttpClient) { }

  changeUserId(id:string){
    this.userId = id;
  }
  getUserId(){
    return this.userId;
  }
  newUser(Users: usu) {
    let request = Users;
    this.uri = '';
    this.uri = url;
    
    this.uri += 'user/add-user'
    let body = request;
    let headers = new Headers({
      'content-Type': 'application/json',
    });
    return this.http.post(this.uri, body)
  }
  errorHandler(error: HttpErrorResponse) {
    console.log("estoy en errorHandler")
     console.log(error);
     let er = '';
     let mos = [];
     let mos2 = [];
     let mostrar = '';
      console.log(error.error);
      console.log(JSON.stringify(error));
      er = JSON.stringify(error);
      er.substr(6, 11);
      console.log(er.substr(18, 37));
      console.log(er[0]);
       mos = er.split(',');
       console.log(mos[0]);
       mos2 = mos[0].split('"');
       console.log(mos2);
       
      console.log(error);
  
  
  
     const type = ['', 'info', 'success', 'warning', 'danger', 'rose', 'primary'];
     let msgError = "";
     const color = 4;
    //  let err = JSON.stringify(error);
    
     if(error.status === 0){
       msgError  = 'Servicios no disponibles,Consulte al administrador.';
     } else {
       msgError = '<b>Error</b>: ' + mos2[3];
     }
     $.notify({
         icon: 'notifications',
         message: msgError
     }, {
         type: type[color],
         timer: 10000,
         placement: {
             from: 'top',
             align: 'right'
         },
         template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
           // tslint:disable-next-line:max-line-length
           '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
           '<i class="material-icons" data-notify="icon">notifications</i> ' +
           '<span data-notify="title">{1}</span> ' +
           '<span data-notify="message">{2}</span>' +
           '<div class="progress" data-notify="progressbar">' +
             // tslint:disable-next-line:max-line-length
             '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
           '</div>' +
           '<a href="{3}" target="{4}" data-notify="url"></a>' +
         '</div>'
     });
     const err = "Ocurrio un error";
     return err;
   }
   getUser(id:string){

    this.uri = '';
    this.uri = url;
    
    this.uri += 'user/get-user?id='+ id;

    // let headers = new Headers({
    //   'content-Type': 'application/json',
    //   'x-auth-token': localStorage.getItem("token")

      
    // });
    return this.http.get(this.uri)

   }
   putUser(user:usu){
    let request = user;
    this.uri = '';
    this.uri = url;
    
    this.uri += 'user/update-user'
    let body = request;
    let headers = new Headers({
      'content-Type': 'application/json',
    });
    return this.http.put(this.uri, body)


   }
   getUsers(type:string):any{
    this.uri = '';
    this.uri = url;
    
    this.uri += 'user/get-users?profile='+ type;

    // let headers = new Headers({
    //   'content-Type': 'application/json',
    //   'x-auth-token': localStorage.getItem("token")

      
    // });
    return this.http.get(this.uri)
  }

}
