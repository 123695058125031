import { Component, OnInit } from '@angular/core';
import { User } from '../../intefaces/user.interface';
import { UsersService } from '../../services/users.service';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { usu } from 'src/app/intefaces/usuario.interface';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  
  users: User = {
    userName:'',
    name: '',
    surname: '',
    email: '', 
    password: '',
    profile: '',
    phone: null,
    status: true
  };
  types: string[] = ['Analista', 'Comercial','Administrador'];
  user:usu = {
    User: this.users,
  }
  id:any ='';
  idRoute= ''
  constructor(private userService:UsersService, private router:Router ,private route:ActivatedRoute) {
    this.idRoute = this.route.snapshot.paramMap.get('id');
    //console.log(this.idRoute);
    if(this.idRoute == 'edit'){
      this.id = this.userService.getUserId();
      //console.log(this.id);
      this.userService.getUser(this.id).subscribe(data =>{
        //console.log(data);
        this.user.User = data[0];
        this.users = data[0];
      })
    }

    // this.route.params.subcribe(parametros =>{
    //   this.id = parametros['id'];
    //   if(this.id == 'edit'){
    //     //console.log('editar')
    //   }
    // })
   }

  ngOnInit() {
  }

  save() {
    if(this.idRoute == 'edit'){
      
      this.user.User = this.users;
      this.user.User.status = true;
      //console.log(this.user);
      this.userService.putUser(this.user).subscribe(data =>{
       
          swal({
            title: "Editado",
            text: "",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-success",
            type: "success",
            onAfterClose: () => this.router.navigate(['/dashboard/Users'])
          }).catch(swal.noop)
        
      }, error => {
        swal({   title: "Ups!",   
        text: "El usuario ya  existe.",
        type:"error",   
        timer: 2000,   
        showConfirmButton: false 
   });
      });


    }else {
      this.user.User = this.users;
      this.user.User.status = true;
      //console.log(this.user);
      
      this.userService.newUser(this.user).subscribe(data =>{
       
          swal({
            title: "Guardado",
            text: "",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-success",
            type: "success",
            onAfterClose: () => this.router.navigate(['/dashboard/Users'])
          }).catch(swal.noop)
        
      }, error => {
        swal({   title: "Ups!",   
        text: "El usuario ya  existe.",
        type:"error",   
        timer: 2000,   
        showConfirmButton: false 
   });
      });
    }
    
  }

}
