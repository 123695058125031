import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from '../form/form.component';
import { UsersComponent } from '../users/users.component';
import { UserComponent } from '../userpage/user.component';
import { OrderComponent } from '../order/order.component';
import { ReportsComponent } from '../reports/reports.component';
import { RequestComponent } from '../request/request.component';
import { AssignmentComponent } from '../request/assignment/assignment.component';
import { KeysPipe } from '../pipes/keys.pipe';
import { AdminLayoutComponent } from '../layouts/admin/admin-layout.component';
import { ClinicComponent } from '../clinics/clinic/clinic.component';
import { ClinicsComponent } from '../clinics/clinics.component';
import { SearchComponent } from '../search/search.component';
import { TableRateComponent } from '../rate/table-rate/table-rate.component';
import { RateComponent } from '../rate/rate.component';
import { DocumentIdPipe } from '../pipes/document-id.pipe';
import { MyRequestComponent } from '../my-request/my-request.component';
import { RatesComponent } from '../rate/rates/rates.component';
import { TableComponent } from '../table/table.component';
import { AuthLayoutComponent } from '../layouts/auth/auth-layout.component';
import { APP_ROUTING_WORK_fLOW } from './workflow.routing';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxCurrencyModule } from 'ngx-currency';
import { SidebarModule } from '../sidebar/sidebar.module';
import { NavbarModule } from '../shared/navbar/navbar.module';

@NgModule({
    declarations: [
        FormComponent,
        // UsersComponent,
        // UserComponent,
        // OrderComponent,
        // ReportsComponent,
        // RequestComponent,
        // AssignmentComponent,
        // KeysPipe,
        // TableRateComponent,
        // RateComponent,
        // DocumentIdPipe,
        // MyRequestComponent,
        // SearchComponent,
        // ClinicsComponent,
        // RatesComponent,
        // TableComponent,
        // ClinicComponent,
        // AdminLayoutComponent,
        // AuthLayoutComponent
    ],
    imports: [ 
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        MaterialModule,
        NgxCurrencyModule,
        NavbarModule,
        SidebarModule,
        APP_ROUTING_WORK_fLOW ],
    exports: [],
    providers: [],
})
export class WorkflowModule {}