import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralGuardGuard implements CanActivate {
  estado = true;
  usu = localStorage.getItem('usu');
  constructor(private router:Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      
      if(this.usu === null){
        this.router.navigate(['Home']);
        console.log("No Tienes permisos")
        this.estado = false
      }
    return this.estado;
  }
  
}
