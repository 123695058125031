import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(private spinnerService:SpinnerService ){
    console.log('interceptor')
  }
  intercept(req: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {
    this.spinnerService.llamarSpinner()
    return next.handle(req).pipe(
      finalize(()=> this.spinnerService.detenerSpinner())
    );
  }
}