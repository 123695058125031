import { Injectable } from '@angular/core';
import { Http ,Headers } from '@angular/http';
import { url } from '../intefaces/url';
import { map, catchError } from 'rxjs/operators';
import { Details } from '../intefaces/details.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { error } from 'protractor';
import swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  uri = '';
  details: Details = {
    loanId: '',
    customerId: '',
    userId: ''
  }
  
  constructor(private http:HttpClient) { 

    this.uri = url;
  }

  changeDetails(deta:Details){
    this.details = deta;

  }
  estadoDetails(cuerpo:any):Observable<any>{
    this.uri = '';
    this.uri = url;
    this.uri += '/request/user'
    return this.http.post(this.uri,cuerpo)
  }
  getDetails(){
    return this.details;

  }
  putCustomer(customer:any):any{
    let request = customer;
    this.uri = '';
    this.uri = url;
    let body = request;
    this.uri += '/customer'
    //console.log(body);
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.put(this.uri, body)

  }
  putLoan(loan:any):any{
    let request = loan;
    this.uri = '';
    this.uri = url;
    let body = request;
    this.uri += 'request/update'
    //console.log(body);
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.put(this.uri, body, )
  }
  postDetails(detail:Details):any{
    let request = detail;
    this.uri = '';
    this.uri = url;
    let body = request;
    this.uri += 'request/details'
    //console.log(body);
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.post(this.uri, body)
  }

  getAllRequest():any{
    let request = {
      userId: "NA"
    };
    this.uri = '';
    this.uri = url;
    let body = request;
    this.uri += 'requests'
    //console.log(body);
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.post(this.uri, body)


  }

  getCustomer(cc:string):any{
    this.uri = '';
    this.uri = url;
    
    this.uri += 'request/get-customer?id='+ cc;

    // let headers = new Headers({
    //   'content-Type': 'application/json',
    //   'x-auth-token': localStorage.getItem("token")

      
    // });
    return this.http.get(this.uri)
  //   return this.http.get(this.uri).pipe(
  //     map(res=> res.json()),
  //     catchError(error =>{
  //       //console.log(error.status);
  //       swal({   title: "Ups!",   
  //       text: "No se encontraron resultados",
  //       type:"error",   
  //       timer: 2000,   
  //       showConfirmButton: false 
  //  });
  //       return  throwError(  error.status);
  //     })
  //   )
  }
  errorHandler(error: HttpErrorResponse) {
    //console.log(error);
    return  (error);
}
  getRequest(id:string):any{
    let request = {
      userId: id
    }; 



    
    this.uri = '';
    this.uri = url;
    let body = request;
    this.uri += 'requests'
    //console.log(body);
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.post(this.uri, body)
  }
}
