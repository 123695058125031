import { Component, OnInit } from '@angular/core';

import { RequestService } from '../services/request.service'
import { Requests } from '../intefaces/requests.interface';
import { Details } from '../intefaces/details.interface';
import { Router } from '@angular/router';
declare interface DataTable {
  headerRow: string[];
  
  dataRows: any[];
}
declare interface info {
  fecha:any,
  solicitud:any,
  identificacion:string,
  estado:string,
  valorT:any,
  valorS:any,
  financiera:String,

} 
declare const $: any;
@Component({
  selector: 'app-my-request',
  templateUrl: './my-request.component.html',
  styleUrls: ['./my-request.component.css']
})
export class MyRequestComponent implements OnInit {
  public dataTable: DataTable;
  requests:Requests[]= [];
  //roll back de web services
  user =localStorage.getItem('iux');
  constructor(private requestServices:RequestService, private router:Router) { 
    // //console.log(this.user);
    this.requestServices.getRequest(this.user).subscribe(data=>{
      // //console.log(data);
      this.requests =  data;
      this.dataTable = {
        headerRow :['Clinica','Fecha', 'Titular', 'Identificación','Comercial','Analista' ,'Estado', 'Valor solicitado','Financiera','Detalles'],
        dataRows: this.requests,
      };
      
    })
    // //console.log(this.requests)
    
  }

  ngOnInit() {
    let user = localStorage.getItem('iux');
    this.requestServices.getRequest(user).subscribe(data=>{
      this.requests =  data;
      // //console.log(data);
  
      this.dataTable = {
        headerRow :['Clinica','Fecha', 'Titular', 'Identificación','Comercial','Analista' ,'Estado', 'Valor solicitado','Financiera','Detalles'],
        dataRows: this.requests,
      };
    })
  }
  ngAfterViewInit() {
    
   
    $('#datatables').DataTable({
      "pagingType": "full_numbers",
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Buscar asignación",
      },
      

    });
    const table = $('#datatables').DataTable();

     // Edit record
      table.on('click', '.edit', function(e) {
        const $tr = $(this).closest('tr');
        const data = table.row($tr).data();
        alert('You press on Row: ' + data[0] + ' ' + data[1] + ' ' + data[2] + '\'s row.');
        e.preventDefault();
      });

      // Delete a record
      table.on('click', '.remove', function(e) {
        const $tr = $(this).closest('tr');
        table.row($tr).remove().draw();
        e.preventDefault();
      });

      //Like record
      table.on('click', '.like', function(e) {
        alert('You clicked on Like button');
        e.preventDefault();
      });

      $('.card .material-datatables label').addClass('form-group');
      

    }
    saveDetails(id:string, loan:string){
      //console.log(id);
      //console.log(loan);
      let details:Details = {
        loanId: loan,
        customerId: id
      }
      this.requestServices.changeDetails(details);
      this.router.navigate(['/dashboard/Request/id']);
    }

}
