import { Component, OnInit } from '@angular/core';
import { Requests } from '../intefaces/requests.interface';
import { RequestService } from '../services/request.service';
import { Router } from '@angular/router';
import { ClinicsService } from '../services/clinics.service';
import { Clinic } from '../intefaces/clinic.interface';
import swal from 'sweetalert2';
declare interface DataTable {
  headerRow: string[];
  
  dataRows: any[];
}
declare const $: any;
@Component({
  selector: 'app-clinics',
  templateUrl: './clinics.component.html',
  styleUrls: ['./clinics.component.css']
})

export class ClinicsComponent implements OnInit {

  public dataTable: DataTable;
  requests:Requests[]= [];
  clinics:Clinic[] = []
  user =localStorage.getItem('iux');
  constructor(private clinicServices:ClinicsService, private router:Router) { 
   
   
    this.clinicServices.getClinics().subscribe(data=>{
      // //console.log(data);
      this.clinics = data;
      this.dataTable = {
        headerRow :['Clinica','Zona','Editar'],
        dataRows: this.clinics,
      };
      
    })
  
    
  }
  delete(clinica:Clinic){
      clinica.status= false;
      // //console.log(clinica);
      this.clinicServices.putClinic(clinica).subscribe(data =>{
        // //console.log(data)
        swal({
          title: 'Eliminar',
          text: "Seguro que quiere eliminar?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger',
          confirmButtonText: 'Si',
           buttonsStyling: false
        }).then((result) => {
          if (result.value) {
            
            swal(
              {
                title: 'Eliminado!',
                text: '',
                type: 'success',
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false,
                onAfterClose: () => this.router.navigate(['/dashboard/Form'])
              }
            )
          }
        })
        
      })
  }
  saveId(id:string){
    this.clinicServices.postId(id);
    this.router.navigate(['/dashboard/Clinic/edit']);
  }

  ngOnInit() {
    this.clinicServices.getClinics().subscribe(data=>{
      // //console.log(data);
      this.clinics = data;
      this.dataTable = {
        headerRow :['Clinica','Zona','Editar'],
        dataRows: this.clinics,
      };
      
    })
    
  }
  ngAfterViewInit() {
    
   
    $('#datatables').DataTable({
      "pagingType": "full_numbers",
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Buscar clinica",
      },
      

    });
    const table = $('#datatables').DataTable();

     // Edit record
      table.on('click', '.edit', function(e) {
        const $tr = $(this).closest('tr');
        const data = table.row($tr).data();
        alert('You press on Row: ' + data[0] + ' ' + data[1] + ' ' + data[2] + '\'s row.');
        e.preventDefault();
      });

      // Delete a record
      table.on('click', '.remove', function(e) {
        const $tr = $(this).closest('tr');
        table.row($tr).remove().draw();
        e.preventDefault();
      });

      //Like record
      table.on('click', '.like', function(e) {
        alert('You clicked on Like button');
        e.preventDefault();
      });

      $('.card .material-datatables label').addClass('form-group');
      

    }
  

}
