import { Component, OnInit } from '@angular/core';
import { Requests } from '../intefaces/requests.interface';
import { RequestService } from '../services/request.service';
import { Router } from '@angular/router';
import { Details } from '../intefaces/details.interface';
import swal from 'sweetalert2';
declare interface DataTable {
  headerRow: string[];
  
  dataRows: any[];
}
declare const $: any;
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit {
  public dataTable: DataTable;
  requests:Requests[]= [];
  bandera=false;
  cc =  '';
  user =localStorage.getItem('iux');
  constructor(private requestServices:RequestService, private router:Router) { 
    console.log(this.user);
  
    
  }

  search(){
    this.requestServices.getCustomer(this.cc).subscribe(data =>{
      console.log(data);
      this.requests =  data;
      this.dataTable = {
        headerRow :['Clinica','Fecha', 'Titular', 'Identificación','Comercial','Analista' ,'Estado', 'Valor solicitado','Financiera','Detalles'],
        dataRows: this.requests,
      };
      this.bandera = true;
      if(data === 400){
        console.log('error');
      }
    }), error =>{
      console.log('esto es un error');
      swal({   title: "Ups!",   
             text: "No se encontraron resultados",
             type:"error",   
             timer: 2000,   
             showConfirmButton: false 
        });
    }
  }
  

  ngOnInit() {
    let user = localStorage.getItem('iux');
    this.requestServices.getRequest(user).subscribe(data=>{
      this.requests =  data;
      console.log(data);
  
      this.dataTable = {
        headerRow :['Clinica','Fecha', 'Titular', 'Identificación','Comercial','Analista' ,'Estado', 'Valor solicitado','Financiera','Detalles'],
        dataRows: this.requests,
      };
    })
  }
  saveDetails(id:string, loan:string){
    console.log(id);
    console.log(loan);
    let details:Details = {
      loanId: loan,
      customerId: id
    }
    this.requestServices.changeDetails(details);
    this.router.navigate(['/dashboard/Request/id']);
  }

  // ngAfterViewInit() {
    
   
  //   $('#datatables').DataTable({
  //     "pagingType": "full_numbers",
  //     "lengthMenu": [
  //       [10, 25, 50, -1],
  //       [10, 25, 50, "All"]
  //     ],
  //     responsive: true,
  //     language: {
  //       search: "_INPUT_",
  //       searchPlaceholder: "Buscar asignación",
  //     },
      

  //   });
  //   const table = $('#datatables').DataTable();

  //    // Edit record
  //     table.on('click', '.edit', function(e) {
  //       const $tr = $(this).closest('tr');
  //       const data = table.row($tr).data();
  //       alert('You press on Row: ' + data[0] + ' ' + data[1] + ' ' + data[2] + '\'s row.');
  //       e.preventDefault();
  //     });

  //     // Delete a record
  //     table.on('click', '.remove', function(e) {
  //       const $tr = $(this).closest('tr');
  //       table.row($tr).remove().draw();
  //       e.preventDefault();
  //     });

  //     //Like record
  //     table.on('click', '.like', function(e) {
  //       alert('You clicked on Like button');
  //       e.preventDefault();
  //     });

  //     $('.card .material-datatables label').addClass('form-group');
      

  //   }

}
