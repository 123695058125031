import { Component, OnInit, Input } from '@angular/core';
import { Rate } from 'src/app/intefaces/rate.interface';
import { RateService } from 'src/app/services/rate.service';

declare interface DataTable {
  headerRow: string[];
  
  dataRows: any[];
}
declare const $: any;
@Component({
  selector: 'app-table-rate',
  templateUrl: './table-rate.component.html',
  styleUrls: ['./table-rate.component.css']
})
export class TableRateComponent implements OnInit {

  public dataTable: DataTable;
  rates:Rate[] = []
  @Input() prubHijo: string  = 'aun no ha cambiado';
  constructor(private rateServices:RateService) { 
    // //console.log(this.prubHijo);
    this.rates = this.rateServices.getRateTable();
    // //console.log(this.rates);
    this.dataTable = {
      headerRow :['TASA','Editar'],
      dataRows: this.rates,
    };

  }
  ngOnInit() {
  }

}
