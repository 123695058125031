import { Injectable } from '@angular/core';
import { url} from '../intefaces/url';
import { Http,Headers } from '@angular/http';
import { HttpErrorResponse  } from '@angular/common/http';
import { map, catchError, timestamp } from 'rxjs/operators';
import { Clinic } from '../intefaces/clinic.interface';
declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class ClinicsService {
  uri = '';
  idClinic = '';
  constructor(private http:Http) {

    this.uri = url;

    
   }
   getClinic(id:string){
    this.uri = '';
    this.uri = url;
    let headers = new Headers({
     
      
      'content-Type': 'application/json',
      
    });
    this.uri +='clinics/get-clinic?id='+id;
    console.log("Esta es la url servicio get : " + this.uri)
    return this.http.get(this.uri).pipe(
      map(res=> res.json())
    )


   }
   putClinic(clinic:Clinic){
    this.uri = '';
    this.uri = url;
    let body = clinic;
    this.uri += 'clinics/update-clinic';
    const current = new Date();
    console.log(current);
    console.log(body);
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.put(this.uri, body, {headers: headers}).pipe(
      map(res => res.json())
    );
   }

   postId(id:string){
     this.idClinic = id;
    


   }

   getId(){
    return this.idClinic;
   }

   newClinic(clinic: Clinic) {
    this.uri = '';
    this.uri = url;
    let body = clinic;
    this.uri += 'clinics/add-clinic'
    console.log(body);
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.post(this.uri, body, {headers: headers}).pipe(
      map(res => res.json())
    );
  }

   getClinics(){
    this.uri = '';
    this.uri = url;
    let headers = new Headers({
     
      
      'content-Type': 'application/json',
      
    });
    this.uri +='clinics/get-clinics';
    console.log("Esta es la url servicio get : " + this.uri)
    return this.http.get(this.uri).pipe(
      map(res=> res.json())
    )
  }
}
