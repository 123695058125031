import { Component, OnInit } from '@angular/core';
import { Clinic } from '../intefaces/clinic.interface';
import { ClinicsService } from '../services/clinics.service';
import { customerRequest } from '../intefaces/customerRequest.interface';
import { CustomersService } from '../services/customers.service';
import { Customer } from '../intefaces/customer.interface';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { ReportsService } from '../services/reports.service';
declare const $: any;
@Component({
    selector: 'app-pack',
    templateUrl: './pack.component.html',
    styleUrls: ['./pack.component.css']
})
export class PackComponent implements OnInit {
    clinic:Clinic[] = [];
    startDate= '';
    finDate= '';
    idReport = '';
    reportShow =false;
    beneficiario = {
        customerId:'',
        documentId:null,
        beneficiaryDocumentId:null,
        pacientDocumentId:null,
        pacientId:null,
        beneficiaryBirthday:null,
        beneficiaryPhone:null,
        beneficiaryEmail:'',
        beneficiaryNames:'',
        beneficiarySurnames:'',
        beneficiaryCity:'',
        beneficiaryClinic:'',
        names: '',
        surnames: '',
        birthday: null,
        phone: null,
        email: '',
        city: '',
        clinic:null,
        dentist: ''
    }
    getNewClienteOb = {
      pacientId : '',
      names: '',
      surnames: '',
      pacientDocumentId:null,
      birthday: null,
      phone: null,
      email: '',
      city: '',
      clinic:null,
      dentist: ''
    }
    customerRequest:customerRequest ={
        activity: "",
        address: "",
        anotherIncome: "",
        birthday: "",
        city: "",
        customerId: null,
        district: "",
        documentDate: "",
        documentId: 0,
        documentType: "",
        email: "",
        gender: "",
        income: 0,
        names: "",
        phone: 0,
        surnames: "",
        timestamp: 0,
        pension:"",
        eps: "",
        birthplace:""
      }
      newCustomerOb = {
        names: '',
        surnames: '',
        pacientDocumentId :null,
        birthday: null,
        phone: null,
        email: '',
        city: '',
        clinic:null,
        dentist: ''
      }
      customer:Customer = {
        customerId:'',
        names: '',
        surnames: '',
        gender: '',
        documentId:null,
        documentType: '',
        documentDate: null,
        birthday: null,
         phone: null,
         email: '',
         activity: '',
         income: null,
         anotherIncome: null,
         address: null,
         city: '',
         district: '',
         pension: '',
         eps : '',
         birthplace: ''
     
      }
    numeroDocumento = null
    encontrar = false
    activarCustomer = false;
    activarBeneficiary = false;
    beneficiarys = [];
    ocultarBuscador = false;
    report = []
    bandera = false
    descargar= false;
    constructor(private clinicServices:ClinicsService,private customerServices:CustomersService,private reportServices:ReportsService) { 
        this.listClinics()
    }
  
  limpiarAll(){
    this.encontrar =  false
    this.numeroDocumento = null
  }
  
 limpiarBenificiario(){ 
  this.search();
  this.activarBeneficiary = false;
    this.beneficiario.customerId = null
    this.beneficiario.documentId = null
    this.beneficiario.beneficiaryDocumentId = null
    this.beneficiario.beneficiaryBirthday = null
    this.beneficiario.beneficiaryPhone = null
    this.beneficiario.beneficiaryEmail = null
    this.beneficiario.beneficiaryNames = null
    this.beneficiario.beneficiarySurnames = null
    this.beneficiario.beneficiaryCity = null
    this.beneficiario.beneficiaryClinic = null
 }
 consultar(){
  let start = moment(this.startDate).format("YYYY-MM-DD").toString();
  let date = new Date(this.finDate);
  console.log(date);
  date.setDate(date.getDate() + 1);
  
  
  console.log(date);
  this.finDate = moment(date).format("YYYY-MM-DD").toString() ;
  let res = {
    startDate: start,
    finDate: this.finDate,
    pacientId : Number (this.idReport),

  }
  this.reportServices.packReport(res).subscribe(data =>{
    this.bandera = true
    this.report = data;
    this.descargar = true;
  })
 }
 arrayObjToCsv(ar) {
  //comprobamos compatibilidad
  if(window.Blob && (window.URL )){
    var contenido = "",
      d = new Date(),
      blob,
      reader,
      save,
      clicEvent;
    //creamos contenido del archivo
    for (var i = 0; i < ar.length; i++) {
      //construimos cabecera del csv
      if (i == 0)
        contenido += Object.keys(ar[i]).join(";") + "\n";
      //resto del contenido
      contenido += Object.keys(ar[i]).map(function(key){
              return ar[i][key];
            }).join(";") + "\n";
    }
    //creamos el blob
    blob =  new Blob(["\ufeff", contenido], {type: 'text/csv'});
    //creamos el reader
    var reader:any = new FileReader();
    reader.onload = function (event) {
      //escuchamos su evento load y creamos un enlace en dom
      save = document.createElement('a');
      save.href = event.target.result;
      save.target = '_blank';
      //aquí le damos nombre al archivo
      save.download = "Reporte.csv";
      try {
        //creamos un evento click
        clicEvent = new MouseEvent('click', {
          'view': window,
          'bubbles': true,
          'cancelable': true
        });
      } catch (e) {
        //si llega aquí es que probablemente implemente la forma antigua de crear un enlace
        clicEvent = document.createEvent("MouseEvent");
        clicEvent.initEvent('click', true, true);
      }
      //disparamos el evento
      save.dispatchEvent(clicEvent);
      //liberamos el objeto window.URL
      (window.URL ).revokeObjectURL(save.href);
    }
    //leemos como url
    reader.readAsDataURL(blob);
  }else {
    //el navegador no admite esta opción
    alert("Su navegador no permite esta acción");
  }
};
 ownloadFile(){
  for(let i =0; i< this.report.length; i++){
    delete this.report[i].reportId;
    delete this.report[i].loanId;
    delete this.report[i].customerId;
    this.report[i].nameClinc = this.report[i].clinic.name
    this.report[i].nameClincBeneficiary = this.report[i].beneficiaryClinic.name
  }
  let reporteFinal = this.report
  console.log(reporteFinal)
  reporteFinal.map(element =>{
    // element.nameClinc =  element.clinc.name
    // element.nameClincBeneficiary =  element.beneficiaryClinic.name
    delete element.clinc
    delete element.beneficiaryClinic
  })
  this.arrayObjToCsv(reporteFinal);
 }
 activarReporte(){
  this.bandera =false
  this.report = []
  this.encontrar =false;
  this.activarCustomer = false
  this.ocultarBuscador = false
  this.descargar = false
  if(this.reportShow){

    this.reportShow = false;
  }else{
    this.reportShow = true;
  }
 }
 
 mostrarBuscador(){
  this.report = []
  this.reportShow = false;
  this.activarCustomer = false
  this.bandera =false
  this.encontrar =false;
  this.descargar = false
  if(this.ocultarBuscador){
    this.ocultarBuscador =  false;
  }else{
    this.ocultarBuscador = true;
  
  }
}
 newCustomer(){
  this.report = []
  this.bandera =false
  this.ocultarBuscador = false
  this.reportShow = false;
  this.encontrar =false;
  this.descargar = false
  if(this.activarCustomer){
    this.activarCustomer = false
  }else{
    this.activarCustomer =  true
  }
  console.log(this.activarCustomer)
 }
 newBeneficiary(){
  if(this.activarBeneficiary){
    this.activarBeneficiary = false
  }else{
    this.activarBeneficiary =  true
  }
 }
    getBeneficiary(){
        let body = {
          pacientId:this.getNewClienteOb.pacientId
        }
        this.customerServices.getBeneficiary(body).subscribe(data =>{
            this.beneficiarys = data;
            // console.log(data)
        },(error)=>{
          console.log(error)
          this.beneficiarys = []
        })
    }
    saveBeneficiary(){
      console.log(this.getNewClienteOb.pacientId)
      console.log(this.getNewClienteOb)
        // this.beneficiario.beneficiaryDocumentId =  this.getNewClienteOb.beneficiaryDocumentId
        
 


   
        this.beneficiario.dentist = this.getNewClienteOb.dentist;
        this.beneficiario.clinic = this.getNewClienteOb.clinic;
        this.beneficiario.city = this.getNewClienteOb.city;
        this.beneficiario.email = this.getNewClienteOb.email;
        this.beneficiario.birthday = this.getNewClienteOb.birthday;
        this.beneficiario.pacientDocumentId = this.getNewClienteOb.pacientDocumentId;
        this.beneficiario.surnames = this.getNewClienteOb.surnames;
        this.beneficiario.names = this.getNewClienteOb.names;
        this.beneficiario.phone = this.getNewClienteOb.phone;
        this.beneficiario.beneficiaryDocumentId  = Number (this.beneficiario.beneficiaryDocumentId )
        this.beneficiario.pacientDocumentId = Number (this.getNewClienteOb.pacientDocumentId)
        this.beneficiario.pacientId = this.getNewClienteOb.pacientId
       
      
        this.customerServices.saveBeneficiary(this.beneficiario).subscribe(data =>{
           
            swal({
                title: "Información guardada correctamente",
                text: "",
                buttonsStyling: false,
                confirmButtonClass: "btn btn-success",
                type: "success",
                onAfterClose: () => {
                //  this.numeroDocumento = null;
                 this.encontrar = true;
                 this.limpiarBenificiario()
                
                }
            }).catch(swal.noop)
        },(error)=>{
            console.log(error)
        })
    }
    limpiarCustomer(){ 
      this.activarCustomer = false
      this.newCustomerOb = {
        names: '',
        surnames: '',
        pacientDocumentId :null,
        birthday: null,
        phone: null,
        email: '',
        city: '',
        clinic:null,
        dentist: ''
      }
    }
    saveNewCustomer(){
        // this.beneficiario.customerId =  this.customer.customerId
        // this.beneficiario.documentId = this.customer.documentId
        // this.beneficiario.beneficiaryDocumentId = Number (this.beneficiario.beneficiaryDocumentId)
        this.newCustomerOb.pacientDocumentId = Number (this.newCustomerOb.pacientDocumentId)
        this.customerServices.newCustomerService(this.newCustomerOb).subscribe(data =>{
           
            swal({
                title: "Información guardada correctamente",
                text: "",
                buttonsStyling: false,
                confirmButtonClass: "btn btn-success",
                type: "success",
                onAfterClose: () => {
                  console.log(data)
                  this.limpiarCustomer();
                  // this.search()
                //  this.numeroDocumento = null;
                //  this.encontrar = false;
                //  this.limpiarBenificiario()
                
                }
            }).catch(swal.noop)
        },(error)=>{
            console.log(error)
        })
    }

    listClinics(){
        this.clinicServices.getClinics().subscribe(data =>{
            this.clinic = data;
          })
    }
    search(){
        this.encontrar = false;
        let body = {
          pacientDocumentId: this.numeroDocumento
        }
        this.customerServices.getNewCustomers(body).subscribe(data=>{
          console.log('respuesta del servicio')
          console.log(data)
          if(data == 'Usuario no econtrado'){
            // //console.log('no hay');
            $.notify({
              icon: 'notifications',
             message: "No se encontró ningún cliente con este número de identificación.",
            }, {
             type: 'warning',
             timer: 1000,
             placement: {
                 from: 'top',
                 align: 'right'
             },
             template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
             '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
             '<i class="material-icons" data-notify="icon">notifications</i> ' +
             '<span data-notify="title">{1}</span> ' +
             '<span data-notify="message">{2}</span>' +
             '<div class="progress" data-notify="progressbar">' +
               '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
             '</div>' +
             '<a href="{3}" target="{4}" data-notify="url"></a>' +
           '</div>'
            })
            this.getNewClienteOb = {
              names: '',
              surnames: '',
              pacientDocumentId :null,
              birthday: null,
              phone: null,
              pacientId:null,
              email: '',
              city: '',
              clinic:null,
              dentist: ''
            }
           
          }else {
            if(data.length > 0){
    
              $.notify({
                icon: 'notifications',
               message: "Se encontró un paciente con este número de identificación.",
              }, {
               type: 'success',
               timer: 1000,
               placement: {
                   from: 'top',
                   align: 'right'
               },
               template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
               '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
               '<i class="material-icons" data-notify="icon">notifications</i> ' +
               '<span data-notify="title">{1}</span> ' +
               '<span data-notify="message">{2}</span>' +
               '<div class="progress" data-notify="progressbar">' +
                 '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
               '</div>' +
               '<a href="{3}" target="{4}" data-notify="url"></a>' +
             '</div>'
              })
              
              this.getNewClienteOb.names= data[0].names,
              this.getNewClienteOb.surnames= data[0].surnames,
              this.getNewClienteOb.pacientDocumentId =data[0].pacientDocumentId,
              this.getNewClienteOb.birthday= data[0].birthday,
              this.getNewClienteOb.phone= data[0].phone,
              this.getNewClienteOb.email= data[0].email,
              this.getNewClienteOb.city= data[0].city,
              this.getNewClienteOb.clinic=data[0].clinic,
              this.getNewClienteOb.dentist= data[0].dentist,
              this.getNewClienteOb.pacientId= data[0].pacientId,
             
              // this.customerRequest = data[0];
              // // //console.log(this.customerRequest);
              // this.customer.activity = this.customerRequest.activity;
              // this.customer.address = this.customerRequest.address;
              // this.customer.anotherIncome = Number( this.customerRequest.anotherIncome);
              // this.customer.birthday = this.customerRequest.birthday;
              // this.customer.city = this.customerRequest.city;
              // this.customer.district = this.customerRequest.district;
              // this.customer.documentId = this.customerRequest.documentId;
              // this.customer.documentDate = this.customerRequest.documentDate;
              // this.customer.documentType = this.customerRequest.documentType;
              // this.customer.email = this.customerRequest.email;
              // this.customer.gender = this.customerRequest.gender;
              // this.customer.income = this.customerRequest.income;
              // this.customer.names = this.customerRequest.names;
              // this.customer.phone = this.customerRequest.phone;
              // this.customer.surnames = this.customerRequest.surnames;
              // this.customer.customerId = data[0].customerId
              // //console.log(this.customer);
              this.encontrar = true;
             this.getBeneficiary();
            }
          }
          
          
          
    
    
    
    
        },(error)=>{
         if(error.status == 400){
          $.notify({
            icon: 'notifications',
            message: error._body
        }, {
            type: 'danger',
            timer: 10000,
            placement: {
                from: 'top',
                align: 'right'
            },
            template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
              // tslint:disable-next-line:max-line-length
              '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
              '<i class="material-icons" data-notify="icon">notifications</i> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
                // tslint:disable-next-line:max-line-length
                '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              '</div>' +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
            '</div>'
        });
         }
        })
    }
    ngOnInit(): void { }
}
