import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
} from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedpluginModule} from './shared/fixedplugin/fixedplugin.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { AppRoutes } from './app.routing';
import { APP_ROUTING } from './app.routes';
import { LoginComponent } from "./login/LoginComponent";
import { HomeComponent } from './home/home.component';
import { FormComponent } from './form/form.component';
import { UsersComponent } from './users/users.component';
import { UserComponent } from './users/user/user.component';
import { OrderComponent } from './order/order.component';
import { ReportsComponent } from './reports/reports.component';
import { KeysPipe } from './pipes/keys.pipe';
import { RequestComponent } from './request/request.component';
import { AssignmentComponent } from './request/assignment/assignment.component';
import { NgxCurrencyModule } from "ngx-currency";
import { DocumentIdPipe } from './pipes/document-id.pipe';
import { SearchComponent } from './search/search.component';
import { MyRequestComponent } from './my-request/my-request.component';
import { ClinicsComponent } from './clinics/clinics.component';
import { ClinicComponent } from './clinics/clinic/clinic.component';
import { RateComponent } from './rate/rate.component';
import { TableComponent } from './table/table.component';
import { TableRateComponent } from './rate/table-rate/table-rate.component';
import { RatesComponent } from './rate/rates/rates.component';
import { WorkflowModule } from './workflow/workflow.module';
import { MaterialModule } from './material.module';
import { WorkflowComponent } from './workflow/workflow.component';

import { NgxSpinnerModule  } from 'ngx-spinner';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './services/interceptor.service';
import { BancaComponent } from './bancaxpress/banca.component';
import { PackComponent } from './pack/pack.component';


@NgModule({
    
    imports:[


      HttpClientModule,
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        NgxSpinnerModule,
        HttpModule,
        MaterialModule,
        MatNativeDateModule,
        SidebarModule,
        NavbarModule,
        NgxCurrencyModule,
        APP_ROUTING ,
        FooterModule,
        FixedpluginModule,
        WorkflowModule
    ],
    declarations: [
      // LoginComponent,
      UsersComponent,
      UserComponent,
      OrderComponent,
      ReportsComponent,
      RequestComponent,
      AssignmentComponent,
      KeysPipe,
      TableRateComponent,
      RateComponent,
      DocumentIdPipe,
      MyRequestComponent,
      SearchComponent,
      ClinicsComponent,
      RatesComponent,
      TableComponent,
      ClinicComponent,
      AdminLayoutComponent,
      AuthLayoutComponent,
      AppComponent,
      HomeComponent,
      WorkflowComponent,
      BancaComponent,
      PackComponent
       
    ],
    providers:[
      {provide: HTTP_INTERCEPTORS, useClass: InterceptorService,multi:true}
    ],
    bootstrap:    [ AppComponent ]
})
export class AppModule { }
