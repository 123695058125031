import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  ur = '';

  files = [];
  constructor() {
   
    
   }
   setCCu(url:any){
    this.ur = url;
    return true;

  }
   
  setFiles(){
    this.files = [];
  }
  getCcU(){
    // //console.log(this.ur)
    return this.ur;
  }
  getFiles(){
    return this.files;
  }
  fileUpload(file) {
    const contentType = file.type;
    let date = new Date();
    let month = date.getMonth();
    let day = date.getDay();
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    
     let fecha = yyyy + '/' + mm + '/' + dd ;
    // //console.log(fecha)
    const l = Math.floor( Math.random() * (99999 - 1000) + 1000);
    const bucket = new S3(
          {
              accessKeyId: 'AKIA4CWZSJBUFPQPPFF5',
              secretAccessKey: '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',
              region: 'us-east-1',
          }
      );
      const params = {
          Bucket: 'dev.alivio.bucket',
          Key:  fecha + '/'+l+'-' + file.name  ,
          Body: file,
          ACL: 'public-read',
          ContentType: contentType
      };
      let fileSave = fecha + '/'+l+'-' + file.name 
      this.files.push(fileSave);

     return new Promise(function (resolve, rejection) {
        bucket.upload(params, function (err,data) {
          if (err) {
              //console.log('EROOR: ',JSON.stringify( err));
              
          }
          //console.log('File Uploaded.', data);
          //console.log(data.Location);
          resolve(data.Location);
          
          
      
      });

    })
   
    
      
      
    }
    
  

}
