import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { ClinicsService } from 'src/app/services/clinics.service';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { animate, style, transition, trigger } from '@angular/animations';
import { banca, responseBanca } from '../intefaces/banca';
import { BancaService } from '../services/banca.service';

@Component({
  selector: 'app-banca',
  templateUrl: './banca.component.html',
  styleUrls: ['./banca.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BancaComponent implements OnInit {
  logXpress = false;
  bancaLog:banca = {
    username:null,
    password:null
  }
  envioReferncia  ={
    monto:null,
    referencia1:null,
    referencia2:null,
    referencia3:null
  }
  token = '';
  valorMultiplicado = 0;
  constructor(private bancaService:BancaService, private router:Router,private route:ActivatedRoute, private cd: ChangeDetectorRef) { 
  


  }

  ngOnInit() {
    this.bancaLog.username = 'DSFF1998'
    this.bancaLog.password = 'H8FbC-b%'
    this.InciarXpress()
  }

  prueba(){

  }
  save(){
   
   return this.bancaService.loginBanca(this.bancaLog).subscribe((data) =>{
      this.logXpress = true;
      
      this.token = data.data.IdToken
      this.cd.detectChanges();
      // swal({
      //   title: "login correcto",
      //   text: "",
      //   buttonsStyling: false,
      //   confirmButtonClass: "btn btn-success",
      //   type: "success",
      //   onAfterClose: () => this.prueba()
      // })
      },(error)=>{
        swal({   title: "Error!",   
        text: "Error de login",
        type:"error",   
        timer: 2000,   
        showConfirmButton: false,
        
      }); 
      })
  }

  async InciarXpress(){
      await this.save()
     
   
    

  }
  finalizar(){
    
    this.envioReferncia.monto= +this.envioReferncia.monto;
    let body = {
      monto:((this.envioReferncia.monto*4)/1000) + (this.envioReferncia.monto*1),
      referencia1: this.envioReferncia.referencia1,
      referencia2:this.envioReferncia.referencia2,
      referencia3:this.envioReferncia.referencia3,
    }
    if(this.envioReferncia.referencia2 == null){
      delete this.envioReferncia.referencia2
      delete body.referencia2
      
    }
    if(this.envioReferncia.referencia3 == null){
      delete this.envioReferncia.referencia3
      delete body.referencia3
    }
  
   
    this.bancaService.extraerUrl(body, this.token).subscribe(data =>{
      
      swal({   title: "Redirigiendo!",   
        text: "...",
        type:"success",   
        timer: 2500,   
        showConfirmButton: false,
        onAfterClose: ()=> window.open(data.data.url)
        
      }); 
      
    },(error)=>{
      console.log(error)
      swal({   title: "Error!",   
        text: error.error.message,
        type:"error",   
        timer: 2000,   
        showConfirmButton: false,
        onAfterClose: () =>{
          if(error.error.message == 'Unauthorized'){
            this.InciarXpress()
          }
        }
      }); 
    })
  }
}
