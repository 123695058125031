import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { Details } from 'src/app/intefaces/details.interface';
import { InfoDetails } from 'src/app/intefaces/infoDetails.interface';
import { customerRequest } from 'src/app/intefaces/customerRequest.interface';
import { Loan } from 'src/app/intefaces/loan.interface';
import { Reference } from 'src/app/intefaces/references.interface';
import { CustomerUpdate } from 'src/app/intefaces/customerUpdate.interface';
import { Financial } from 'src/app/intefaces/financial.interface';
import { RequestUpdate } from 'src/app/intefaces/requestUpdate.interface';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Financials } from 'src/app/intefaces/financials.interface';
import { UsersService } from 'src/app/services/users.service';
import * as AWS from 'aws-sdk';
import { String } from 'aws-sdk/clients/appstream';
import { UploadService } from 'src/app/upload.service';
import { WebsocketService } from 'src/app/services/webSocket.service';
declare const $: any;
@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.css']
})
export class AssignmentComponent implements OnInit {
  details:Details = {
    loanId: '',
    customerId: ''
  }
  customer:customerRequest= {
    activity: '',
    address: '',
    anotherIncome: '',
    birthday: '',
    city: '',
    customerId: '',
    district: '',
    documentDate: '',
    documentId: 0,
    documentType: '',
    email: '',
    gender: '',
    income: 0,
    names: '',
    phone: 0 ,
    surnames: '',
    timestamp: 0,
    
  }
  loan:Loan = {
    requestAmount: 0,
    rate: 0,
    discount: 0,
    total: 0,
    time: 0,
    clinic: '',
    observations: '',
    files:[],
    userId: '',
    createdBy: '',
    financial: [],
    patients :[]
  }
  financials:Financials[] = [];
  info:InfoDetails= {
    Customer:this.customer,
    Loan:this.loan
    
  };
  referenceOne:Reference = {
    name:'',
    surname:'',
    phone:'',
    relationship:''
  }
  referenceTwo:Reference = {
    name:'',
    surname:'',
    phone:'',
    relationship:''
  }
  references:Reference[] = [];
  customerUpdate:CustomerUpdate = {

    customerId:'',
    names: '',
    surnames: '',
    gender: '',
    documentId: 0,
    documentType: '',
    documentDate: '',
    birthday: '',
    phone: 0,
    email: '',
    activity: '',
    income: 0,
    anotherIncome: 0,
    address: '',
    city: '',
    district: '',
    housingType: '',
    housingTime: 0,
    dependents: 0,
    health: '',
    ciio: '',
    references:this.references


  }
  financial:Financial = {
    financialName: '',
    status: '',
    incident: '',
    delivery: '',
    billing: '',
    amount: 0,
    observations: '',
    userId: 'NA',
    userName : '',
    files:[],
    code: ''
  }

  requestUpdate:RequestUpdate ={
    loanId: '',
    customerId: '',
    userId: 'NA',
    financial: this.financial
  }
  asi = false;
  fina:string[] = ['Servicredito','Sistecredito','Addi','Sufi','Brilla','Banco de Bogotá','WELLI','BANCOMEVA','CREDIFAMILIA','MEDIPLAY','CREDIFAMILIA','CREDIPLAY'];
  status:string[] = ['Aprobado', 'Pendiente', 'Negado', 'Anulado', 'Desembolsado'];
  flag = false;
  pro = localStorage.getItem('pro');

  users:any[] = [];
  type= '';
  banderaUs = false;
  asignarUsuario = '';
  usuarioEsta = ''
  usu =  localStorage.getItem('iux');
  files= [];
  viewFiles = [];
  upCc;
  filesBitacora:any = [];
  banderaAd1 = false;
  filesProb = [];
  obsProb = '';
  urlProb = '';
  constructor(private requestServices:RequestService, private router:Router,private userServices: UsersService ,private uploadServices:UploadService ) { 
    // this.WebsocketService.messages.subscribe(msg => {
    //   // this.received.push(msg);
      
    // });
    this.details = this.requestServices.getDetails();
    this.usuarioEsta = this.details.userId;
    this.details.userId = 'NA';
    if(this.usuarioEsta == 'NA' && this.pro != 'Comercial' ){
      //llamar ws
      // this.webSocketOn();
      this.details.userId = this.usu;
    //   $.notify({
    //     icon: 'notifications',
    //    message: "Solicitudes sin Asignar fue actualizado.",
    //   }, {
    //    type: 'warning',
    //    timer: 1000,
    //    placement: {
    //        from: 'top',
    //        align: 'right'
    //    },
    //    template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
    //    '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
    //    '<i class="material-icons" data-notify="icon">notifications</i> ' +
    //    '<span data-notify="title">{1}</span> ' +
    //    '<span data-notify="message">{2}</span>' +
    //    '<div class="progress" data-notify="progressbar">' +
    //      '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
    //    '</div>' +
    //    '<a href="{3}" target="{4}" data-notify="url"></a>' +
    //  '</div>'
    //   })
    }
    this.requestServices.postDetails(this.details).subscribe(data =>{
      console.log(data);
      this.info= data;
      console.log(this.info);
      this.files = this.info.Loan.files;
      this.customerUpdate.activity = this.info.Customer.activity;
      this.customerUpdate.address = this.info.Customer.address;
      this.customerUpdate.anotherIncome = Number( this.info.Customer.anotherIncome);
      this.customerUpdate.birthday = this.info.Customer.birthday;
      this.customerUpdate.city = this.info.Customer.city;
      this.customerUpdate.customerId = this.info.Customer.customerId;
      this.customerUpdate.district = this.info.Customer.district;
      this.customerUpdate.documentDate = this.info.Customer.documentDate;
      this.customerUpdate.documentId = this.info.Customer.documentId;
      this.customerUpdate.documentType = this.info.Customer.documentType;
      this.customerUpdate.email = this.info.Customer.email;
      this.customerUpdate.gender = this.info.Customer.gender;
      this.customerUpdate.income = this.info.Customer.income;
      this.customerUpdate.names = this.info.Customer.names;
      this.customerUpdate.phone = this.info.Customer.phone;
      this.customerUpdate.surnames = this.info.Customer.surnames;
      console.log(this.customerUpdate);
      console.log(this.info);
      this.customerUpdate = data.Customer;
      console.log(this.customerUpdate);
      console.log(this.customerUpdate.references[1]);
      this.financials = this.info.Loan.financial;
      


      console.log(this.financials);
      
      this.financials
      
      this.financials.splice(0,1);
      
      // for(let i =0;i< this.financials.length;i++){
      //     for(let j =0;j< this.financials[i].files.length; j++){
      //         this.getFileBitacora(this.financials[i].files[j]);
      //     }
      //     this.financials[i].files = this.filesBitacora;
      //     //  this.getFileBitacora(this.financials[i]);
      //     this.filesBitacora = [];
      // }
      // console.log(this.financials);
     for(let i = 0; i < this.files.length; i++){

        this.getFile(this.files[i]);


     }
     console.log(this.files.length)
      if(this.customerUpdate.references.length !=0){

        this.referenceOne = this.customerUpdate.references[0];
        this.referenceTwo = this.customerUpdate.references[1];
        
        
        this.flag = true;
        console.log("si tiene");
      }
    })
  }

  recargar(){
    $('#save').attr("disabled", false);
    const jumbo = document.querySelector('.jumbo');
    jumbo.scrollIntoView({behavior: 'smooth'});
    this.financial = {
      financialName: '',
      status: '',
      incident: '',
      delivery: '',
      billing: '',
      amount: 0,
      observations: '',
      userId: 'NA',
      userName : '',
      files:[],
      code: ''
    }
    this.details = this.requestServices.getDetails();
    console.log("aca esta la recarga");
    console.log(this.details);
    this.usuarioEsta = this.details.userId;
    this.details.userId = 'NA';
   
    this.requestServices.postDetails(this.details).subscribe(data =>{
      console.log(data);
      this.info= data;
      console.log(this.info);
      this.files = this.info.Loan.files;
      this.customerUpdate.activity = this.info.Customer.activity;
      this.customerUpdate.address = this.info.Customer.address;
      this.customerUpdate.anotherIncome = Number( this.info.Customer.anotherIncome);
      this.customerUpdate.birthday = this.info.Customer.birthday;
      this.customerUpdate.city = this.info.Customer.city;
      this.customerUpdate.customerId = this.info.Customer.customerId;
      this.customerUpdate.district = this.info.Customer.district;
      this.customerUpdate.documentDate = this.info.Customer.documentDate;
      this.customerUpdate.documentId = this.info.Customer.documentId;
      this.customerUpdate.documentType = this.info.Customer.documentType;
      this.customerUpdate.email = this.info.Customer.email;
      this.customerUpdate.gender = this.info.Customer.gender;
      this.customerUpdate.income = this.info.Customer.income;
      this.customerUpdate.names = this.info.Customer.names;
      this.customerUpdate.phone = this.info.Customer.phone;
      this.customerUpdate.surnames = this.info.Customer.surnames;
      console.log(this.customerUpdate);
      console.log(this.info);
      this.customerUpdate = data.Customer;
      console.log(this.customerUpdate);
      console.log(this.customerUpdate.references[1]);
      this.financials = this.info.Loan.financial;
      


      console.log(this.financials);
      
      this.financials
      
      this.financials.splice(0,1);
      
      // for(let i =0;i< this.financials.length;i++){
      //     for(let j =0;j< this.financials[i].files.length; j++){
      //         this.getFileBitacora(this.financials[i].files[j]);
      //     }
      //     this.financials[i].files = this.filesBitacora;
      //     //  this.getFileBitacora(this.financials[i]);
      //     this.filesBitacora = [];
      // }
      // console.log(this.financials);
     for(let i = 0; i < this.files.length; i++){

        this.getFile(this.files[i]);


     }
     console.log(this.files.length)
      if(this.customerUpdate.references.length !=0){

        this.referenceOne = this.customerUpdate.references[0];
        this.referenceTwo = this.customerUpdate.references[1];
        
        
        this.flag = true;
        console.log("si tiene");
      }
    })


  }
  ngOnInit() {
  }
  saveBitacora(obse:string, adj:string[]){
    this.obsProb = obse;
    this.filesProb = adj;
    console.log(this.filesProb);
    AWS.config.credentials = new AWS.Credentials({
      accessKeyId: 'AKIA4CWZSJBUFPQPPFF5', secretAccessKey: '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'
    });
    const params = {
      Bucket: 'dev.alivio.bucket',
      Key: this.filesProb[0]
    };
    let s3 = new AWS.S3();
    s3.getSignedUrl('getObject',params, (err, url) =>{
      if(err){
        console.log(err);
      }else{
        console.log("aca esta la url");
        console.log(url);
        this.filesProb[0] = url;
      }
    })
    s3.getObject(params, function(err, data) {
      if (err) {
        console.error(err); // an error occurred
      } else {
        console.log("aca esta el serivicio get de el file")
        console.log(data);
        // const fil = new TextDecoder('utf-8').decode(data);
        // console.log(file);
      }
    });
  }

  getFileBitacora(file:string){
    AWS.config.credentials = new AWS.Credentials({
      accessKeyId: 'AKIA4CWZSJBUFPQPPFF5', secretAccessKey: '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'
    });
    const params = {
      Bucket: 'dev.alivio.bucket',
      Key: file
    };
    let s3 = new AWS.S3();
    s3.getSignedUrl('getObject',params, (err, url) =>{
      if(err){
        console.log(err);
      }else{
        console.log("aca esta la url");
        console.log(url);
        this.filesBitacora.push(url);
      }
    })
    s3.getObject(params, function(err, data) {
      if (err) {
        console.error(err); // an error occurred
      } else {
        console.log("aca esta el serivicio get de el file")
        console.log(data);
        // const fil = new TextDecoder('utf-8').decode(data);
        // console.log(file);
      }
    });

  }
  getFile(file:String){
      // Set up credentials
      AWS.config.credentials = new AWS.Credentials({
        accessKeyId: 'AKIA4CWZSJBUFPQPPFF5', secretAccessKey: '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'
      });
      const params = {
        Bucket: 'dev.alivio.bucket',
        Key: file
      };
      let s3 = new AWS.S3();
      s3.getSignedUrl('getObject',params, (err, url) =>{
        if(err){
          console.log(err);
        }else{
          console.log("aca esta la url");
          console.log(url);
          this.viewFiles.push(url);
        }
      })
      s3.getObject(params, function(err, data) {
        if (err) {
          console.error(err); // an error occurred
        } else {
          console.log("aca esta el serivicio get de el file")
          console.log(data);
          // const fil = new TextDecoder('utf-8').decode(data);
          // console.log(file);
        }
      });


  }

  onChange(event) {
    let file:File = event.target.files[0];
    this.upCc = file;
    console.log(file);
    console.log("entro")
    console.log(event.target.files);
    this.banderaAd1 = true;
    // this.upCc = event.target.files;
  }

  openFile(u:string){
    window.open(u);
  }
  openFiles(num:string){
    console.log(this.viewFiles[num]);
    // window.open(u);
  }
  update(){

    console.log(this.flag)
    if(this.flag){
      this.customerUpdate.references[0] = this.referenceOne;
      this.customerUpdate.references[1] = this.referenceTwo;
      console.log(this.customerUpdate.references);

    }
    else{
      this.customerUpdate.references.push(this.referenceOne);
      this.customerUpdate.references.push(this.referenceTwo);
      console.log(this.customerUpdate);
    }

    


    this.requestServices.putCustomer(this.customerUpdate).subscribe(data =>{
      console.log(data);
      $.notify({
        icon: 'notifications',
       message: data,
      }, {
       type: 'success',
       timer: 1000,
       placement: {
           from: 'top',
           align: 'right'
       },
      })


    });

  }
  tipo(){
    this.userServices.getUsers(this.type).subscribe(data=>{
      console.log(data);
      this.users= data;
      this.banderaUs = true;
     
    })
  }
  async  save(){
    $('#save').attr("disabled", true);
    
    this.requestUpdate.customerId = this.customerUpdate.customerId;
    this.requestUpdate.loanId = this.details.loanId;
    this.financial.userName = localStorage.getItem('usu');
    this.requestUpdate.financial = this.financial;
    if(this.banderaAd1){
      let url;
      url = await this.uploadServices.fileUpload(this.upCc);
      console.log(url);
      //this.request.Loan.files.push(url);
      console.log(this.requestUpdate.financial.files)
      
    }
    if(this.asi){
      this.requestUpdate.financial.userId = localStorage.getItem('iux');
      console.log(this.requestUpdate.financial.userId);
      this.requestUpdate.userId = localStorage.getItem('iux');
    }
    
    console.log("esta es la info enviada")
    console.log(this.requestUpdate);
    this.requestUpdate.userId = localStorage.getItem('iux');
    if(this.asi){
      console.log("entro")
      console.log(this.asignarUsuario);
      this.requestUpdate.userId = this.asignarUsuario 
    }
    if(this.requestUpdate.financial.status == 'Negado' ){
      if(this.requestUpdate.financial.financialName == '' || 
      this.requestUpdate.financial.amount == 0 ||
      this.requestUpdate.financial.code == '' 
      ){
        $('#save').attr("disabled", false);
          swal({
            title: "Validacion incorrecta",
            text: "campos obligatorios por diligenciar!",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-success",
            type: "warning"
           
        })
      }else {
        this.requestUpdate.financial.files= this.uploadServices.getFiles();
        this.uploadServices.setFiles();
        console.log(this.requestUpdate.financial.files);
        this.requestServices.putLoan(this.requestUpdate).subscribe(data =>{
          console.log(data);
          console.log('');
          swal({
            title: "Solicitud guardada",
            text: "",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-success",
            type: "success",
            onAfterClose: () => this.recargar()
        }).catch(swal.noop)
    
        })
      }
      
    }else{

      if(this.requestUpdate.financial.financialName == '' || 
        this.requestUpdate.financial.status == '' ||
        this.requestUpdate.financial.incident == '' ||
        this.requestUpdate.financial.delivery == '' ||
        this.requestUpdate.financial.billing == '' ||
        this.requestUpdate.financial.amount == 0 ||
        this.requestUpdate.financial.code == '' 
        ){
          $('#save').attr("disabled", false);
          swal({
            title: "Validacion incorrecta",
            text: "campos obligatorios por diligenciar!",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-success",
            type: "warning"
           
        })
  
      }else{
        
        this.requestUpdate.financial.files= this.uploadServices.getFiles();
        this.uploadServices.setFiles();
        console.log(this.requestUpdate.financial.files);
        this.requestServices.putLoan(this.requestUpdate).subscribe(data =>{
          console.log(data);
          console.log('');
          swal({
            title: "Solicitud guardada",
            text: "",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-success",
            type: "success",
            onAfterClose: () => this.recargar()
        }).catch(swal.noop)
    
        })
      }
    }


}
}
