import { Injectable } from '@angular/core';
import { url} from '../intefaces/url';
import { Http,Headers } from '@angular/http';
import { HttpErrorResponse  } from '@angular/common/http';
import { User } from '../intefaces/user.interface';
import { map, catchError } from 'rxjs/operators';
import { Request } from '../intefaces/request.interface';
declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  uri = '';
  constructor(private http:Http) {
    this.uri = url;
   }

  saveBeneficiary(beneficiary){
    this.uri = '';
    // this.uri = url;
    this.uri += 'https://5poqwm6zbj.execute-api.us-east-1.amazonaws.com/dev/beneficiary'
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.post(this.uri, beneficiary, {headers: headers}).pipe(
      map(res => res.json())
    ).pipe(catchError(this.errorHandler));
  }
  newCustomerService(body){
    this.uri = '';
    // this.uri = url;
    this.uri += 'https://5poqwm6zbj.execute-api.us-east-1.amazonaws.com/dev/pacient'
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.post(this.uri, body, {headers: headers}).pipe(
      map(res => res.json())
    ).pipe(catchError(this.errorHandler));
  }
  getNewCustomers(body){
    this.uri = '';
    // this.uri = url;
    this.uri += 'https://5poqwm6zbj.execute-api.us-east-1.amazonaws.com/dev/pacient-info'
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.post(this.uri, body, {headers: headers}).pipe(
      map(res => res.json())
    )
  }
  getBeneficiary(body){
    this.uri = '';
    // this.uri = url;
    this.uri += 'https://5poqwm6zbj.execute-api.us-east-1.amazonaws.com/dev/beneficiaries'
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.post(this.uri,body, {headers: headers}).pipe(
      map(res => res.json())
    );
  }
  newCustomer(request: Request) {
    this.uri = '';
    this.uri = url;
    let body = request;
    this.uri += 'request'
    console.log(body);
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.post(this.uri, body, {headers: headers}).pipe(
      map(res => res.json())
    ).pipe(catchError(this.errorHandler));
  }
  getCustomer(cc:any){
    this.uri = '';
    this.uri = url;
    let headers = new Headers({
     
      
      'content-Type': 'application/json',
      
    });
    this.uri +='customer/'+ cc;
    console.log("Esta es la url servicio get : " + this.uri)
    return this.http.get(this.uri).pipe(
      map(res=> res.json())
    )
  }
  errorHandler(error: HttpErrorResponse) {
    console.log("estoy en errorHandler")
     console.log(error);
     let er = '';
     let mos = [];
     let mos2 = [];
     let mostrar = '';
     
      er = JSON.stringify(error);
      er.substr(6, 11);
      console.log(er.substr(18, 37));
      console.log(er[0]);
       mos = er.split(',');
       
       mos2 = mos[0].split('"');
       
       
      console.log(error);
  
  
  
     const type = ['', 'info', 'success', 'warning', 'danger', 'rose', 'primary'];
     let msgError = "";
     const color = 4;
    //  let err = JSON.stringify(error);
    
     if(error.status === 0){
       msgError  = 'Servicios no disponibles,Consulte al administrador.';
     } else {
       msgError = '<b>Error</b>: ' + mos2[3];
     }
     $.notify({
         icon: 'notifications',
         message: msgError
     }, {
         type: type[color],
         timer: 10000,
         placement: {
             from: 'top',
             align: 'right'
         },
         template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
           // tslint:disable-next-line:max-line-length
           '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
           '<i class="material-icons" data-notify="icon">notifications</i> ' +
           '<span data-notify="title">{1}</span> ' +
           '<span data-notify="message">{2}</span>' +
           '<div class="progress" data-notify="progressbar">' +
             // tslint:disable-next-line:max-line-length
             '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
           '</div>' +
           '<a href="{3}" target="{4}" data-notify="url"></a>' +
         '</div>'
     });
     const err = "Ocurrio un error";
     return err;
   }
}
