import { Component, OnInit, Input } from '@angular/core';
import { Rate } from '../intefaces/rate.interface';

declare interface DataTable {
  headerRow: string[];
  
  dataRows: any[];
}
declare const $: any;
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {
  public dataTable: DataTable;
  @Input() rates: Rate[] = [];
  constructor() { 
    this.dataTable = {
      headerRow :['TASA','Editar'],
      dataRows: this.rates,
    };

  }

  ngOnInit() {
  }

}
