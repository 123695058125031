import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Router } from '@angular/router';

declare const $: any;

//Metadata
export interface RouteInfo {
    id?:number;
    path: string;
    title: string;
    type: string;
    icontype: string;
    value:boolean;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
    {
        id:0,
        path: '/dashboard/Form',
        title: 'Crear solicitud',
        type: 'link',
        icontype:'create',
        value: true
    },
    {
        id:1,
        path: '/dashboard/Request',
        title: 'Solicitudes sin asignar',
        type: 'link',
        icontype:'content_paste',
        value: false
    },
    {
        id:2,
        path: '/dashboard/MyRequest',
        title: 'Mis solicitudes',
        type: 'link',
        icontype:'person_search',
        value: false
    },
    {
        id:3,
        path: '/dashboard/Users',
        title: 'Crear Usuario',
        type: 'link',
        icontype:'face',
        value: false
    },
    
    {
        id:4,
        path: '/dashboard/Order',
        title: 'Ordenamiento',
        type: 'link',
        icontype:'clear_all',
        value: false
    },
    {
        id:5,
        path: '/dashboard/Reports',
        title: 'Reportes',
        type: 'link',
        icontype:'bar_chart',
        value: false
    },
    {
        id:6,
        path: '/dashboard/Search',
        title: 'Buscar',
        type: 'link',
        icontype:'search',
        value: false
    },
    {
        id:7,
        path: '/dashboard/Clincs',
        title: 'Clinicas',
        type: 'link',
        icontype:'local_hospital',
        value: false
    },
    {
        id:8,
        path: '/dashboard/Rate',
        title: 'Tasa',
        type: 'link',
        icontype:'trending_up',
        value: false
    },
    {
        id:9,
        path: '/dashboard/bancaxpress',
        title: 'Banca xpress',
        type: 'link',
        icontype:'trending_up',
        value: false
    },
    {
        id:10,
        path: '/dashboard/pack',
        title: 'Pack Familiar',
        type: 'link',
        icontype:'trending_up',
        value: false
    },
    


];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ps: any;
    pro = '';
    usu = '';
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };
    constructor(private router:Router){
        
        
        
        
        
        
        
        

        this.pro = localStorage.getItem('pro');
        this.usu = localStorage.getItem('usu');
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.menuItems[1].value = false;
        this.menuItems[2].value = false;
        this.menuItems[3].value = false;
        this.menuItems[4].value = false;
        this.menuItems[5].value = false;
        this.menuItems[6].value = false;
        this.menuItems[7].value = false;
        this.menuItems[8].value = false;
        this.menuItems[9].value = false;
        this.menuItems[10].value = false;
        if(this.pro =='Admin'){
            // console.log(this.pro)
            this.menuItems[1].value = true;
            this.menuItems[2].value = true;
            this.menuItems[3].value = true;
            
            this.menuItems[5].value = true;
            this.menuItems[6].value = true;
            this.menuItems[7].value = true;
            this.menuItems[8].value = true;

            this.menuItems[9].value = true;
            this.menuItems[10].value = true;
        }
        if(this.pro =='Analista'){
            this.menuItems[1].value = true;
            this.menuItems[2].value = true;
            this.menuItems[6].value = true;
            this.menuItems[9].value = true;
            
        }
        if(this.pro =='Comercial'){
            
            this.menuItems[6].value = true;
            this.menuItems[9].value = true;
            this.menuItems[10].value = true;
        }
      
    }
    close(){
        localStorage.setItem('usu', '');
        localStorage.setItem('pro', '');
        this.pro = '';
        this.usu = '';
        //localStorage.clear();
        this.router.navigate(['/Home']);
    }
    ngOnInit() {

        this.pro = localStorage.getItem('pro');
        this.usu = localStorage.getItem('usu');



        this.menuItems = ROUTES.filter(menuItem => menuItem);
       
        if(this.pro =='Admin'){
            // console.log(this.pro)
            this.menuItems[1].value = true;
            this.menuItems[2].value = true;
            this.menuItems[3].value = true;
            
            this.menuItems[6].value = true;
            this.menuItems[7].value = true;
            this.menuItems[8].value = true;
            this.menuItems[9].value = true;
            this.menuItems[10].value = true;

        }
        if(this.pro =='Analista'){
            this.menuItems[1].value = true;
            this.menuItems[2].value = true;
            this.menuItems[6].value = true;
            this.menuItems[9].value = true;
            
        }
        if(this.pro =='Comercial'){
            
            this.menuItems[6].value = true;
            this.menuItems[9].value = true;
            this.menuItems[10].value = true;
        }
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }
    }
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
}
