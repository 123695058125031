import { Injectable } from '@angular/core';
import { url} from '../intefaces/url';
import { Http,Headers } from '@angular/http';
import { HttpErrorResponse  } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Rate } from '../intefaces/rate.interface';
@Injectable({
  providedIn: 'root'
})
export class RateService {
  uri = '';
  rates:Rate[] = []
  idRate = ''
  constructor(private http:Http) {
    this.uri = url;
   }
   saveId(id:string){
    this.idRate = id;
   }
   getIdRate(){
     return this.idRate;
   }
   saveRate(rate:Rate[]){
    this.rates = rate;
   }
   getRateTable(){
    return this.rates;
   }
   newRate(rate:Rate){

    let request = rate;
    this.uri = '';
    this.uri = url;
    
    this.uri += 'rate/add-rate';
    let body = request;
    let headers = new Headers({
      'content-Type': 'application/json',
    });
    return this.http.post(this.uri, body, {headers: headers}).pipe(
      map(res => res.json())
    );




   }
   getRate(id:string){
    this.uri = '';
    this.uri = url;
    
    this.uri += 'rate/get-rate?id='+ id;

    // let headers = new Headers({
    //   'content-Type': 'application/json',
    //   'x-auth-token': localStorage.getItem("token")

      
    // });
    return this.http.get(this.uri).pipe(
      map(res=> res.json())
    )



   }
   putRate(rate:Rate){
      let request = rate;
      this.uri = '';
      this.uri = url;
      
      this.uri += 'rate/update-rate';
      let body = request;
      let headers = new Headers({
        'content-Type': 'application/json',
      });
      return this.http.put(this.uri, body, {headers: headers}).pipe(
        map(res => res.json())
      );
   }
   getRates(){
    this.uri = '';
    this.uri = url;
    let headers = new Headers({
     
      
      'content-Type': 'application/json',
      
    });
    this.uri +='rate/get-rates';
    console.log("Esta es la url servicio get : " + this.uri)
    return this.http.get(this.uri).pipe(
      map(res=> res.json())
    )
   }

}
